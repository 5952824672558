import React, { useState, useEffect } from 'react'
import countryLib from 'i18n-iso-countries'
import Input from '../input'
import PhoneInput from 'react-phone-number-input'
import es from 'react-phone-number-input/locale/es'
import flags from 'react-phone-number-input/flags'

export default function Step1({
  quoteData,
  state,
  handleInputChange,
  validateInput,
  nextStep,
  handlePhoneInputChange,
}) {
  const [phoneFlags, setFlags] = useState()
  const desiredFlags = [
    'AR',
    'BO',
    'CL',
    'CO',
    'CR',
    'EC',
    'ES',
    'SV',
    'GT',
    'HN',
    'MX',
    'DO',
    'NI',
    'PA',
    'PY',
    'PE',
    'UY',
    'VE',
  ]

  let defaultCountry = 'VE'
  if (quoteData.residence) {
    countryLib.registerLocale(require('i18n-iso-countries/langs/es.json'))
    const rawCountries = countryLib.getNames('es')
    defaultCountry = Object.keys(rawCountries).find(
      key => rawCountries[key] === quoteData.residence
    )
  }

  useEffect(() => {
    for (const f in flags) {
      if (!desiredFlags.includes(f)) {
        delete flags[f]
      }
    }
    setFlags(flags)
  }, [desiredFlags])

  return (
    <div className={state.step1Animation}>
      <div className="form-input">
        <Input
          type="text"
          name="name"
          errors={state.errors['name']}
          placeholder="Nombre *"
          value={state.name}
          onChange={handleInputChange}
          onBlur={validateInput}
        />
      </div>
      <div className="form-input">
        <Input
          type="text"
          name="lastName"
          errors={state.errors['lastName']}
          placeholder="Apellido *"
          value={state.lastName}
          onChange={handleInputChange}
          onBlur={validateInput}
        />
      </div>
      <div className="form-input">
        <Input
          type="text"
          name="nationalID"
          errors={state.errors['nationalID']}
          placeholder="Documento de identidad *"
          value={state.nationalID}
          onChange={handleInputChange}
          onBlur={validateInput}
        />
      </div>
      <div className="form-input">
        <PhoneInput
          name="phone"
          international
          labels={es}
          defaultCountry={defaultCountry}
          placeholder="Teléfono *"
          countries={desiredFlags}
          flags={phoneFlags}
          value={state.phone}
          onBlur={validateInput}
          onChange={handlePhoneInputChange}
        />
      </div>
      <div className="form-input">
        <Input
          type="text"
          name="city"
          errors={state.errors['city']}
          placeholder="Ciudad *"
          value={state.city}
          onChange={handleInputChange}
          onBlur={validateInput}
        />
      </div>
      <div className="form-input">
        <Input
          type="text"
          name="address"
          errors={state.errors['address']}
          placeholder="Dirección *"
          value={state.address}
          onChange={handleInputChange}
          onBlur={validateInput}
        />
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <button
            className="default-button py-3 w-full px-8 mb-8 mt-2 "
            onClick={nextStep}
            type="button"
          >
            Siguiente
          </button>
        </div>
      </div>
    </div>
  )
}
