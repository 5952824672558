import React, { useState } from 'react'
import { navigate } from 'gatsby'

// Components
import Layout from '../../components/layout/layout'
import Title from '../../components/global/title'
import SEO from '../../components/seo'
import RegisterForm from '../../components/forms/register-form'
import PreexistingUser from '../../components/forms/register/preexisting-user'

const RegisterPage = ({ location }) => {
  const [isUniqueUser, setIsUniqueUser] = useState(true)

  const { state } = location
  if (typeof window !== 'undefined') {
    if (!state || !state.selected) {
      navigate('/')
      return null
    }
  }
  const selected = state?.selected || {}
  const quoteData = state?.quoteData || {}
  const refCode = state?.refCode

  return (
    <Layout>
      <SEO title="Registro" />
      {isUniqueUser ? (
        <>
          <Title
            main={'Registro'}
            subTitle={'Regístrate en Alex para comprar tu seguro'}
            options={{ center: true }}
          />
          <RegisterForm
            quoteData={quoteData}
            selected={selected}
            refCode={refCode}
            onExistingUser={() => setIsUniqueUser(false)}
          />
        </>
      ) : (
        <PreexistingUser />
      )}
    </Layout>
  )
}

export default RegisterPage
