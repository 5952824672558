import React, { Component } from 'react'

// Services
import { handleRegister } from '../../services/auth'
import { EventEmitter } from '../../services/events'
import firebase from '../../services/firebase'
import functions from '../../services/functions'

// Component
import Step1 from './register/step-1'
import Step3 from './register/step-3'

// Validators
import step1Validation from './validators/register-1'
import step2Validation from './validators/register-2'
import validate from 'validate.js'

export default class RegisterForm extends Component {
  constructor({ props }) {
    super(props)
    this.firebase = firebase
  }

  state = {
    step: 1,
    name: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    nationalID: '',
    city: '',
    password: '',
    confirmPassword: '',
    terms: false,
    termsOne: false,
    termsTwo: false,
    termsThree: false,
    errors: {},
    step1Animation: 'text-slide-in',
  }

  /**
   * Handle input change
   * @param {Object} event
   */
  handleInputChange = event => {
    if (event) {
      const target = event.target
      const value = target.value
      const name = target.name
      this.setState({
        [name]: value,
      })
    }
  }

  /**
   * Handles phone number input
   * @param {String} phone
   */
  handlePhoneInputChange = phone => {
    this.setState({
      phone,
    })
  }

  /**
   * Validates input values onBlur event
   */
  validateInput = async event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const validator = { ...step1Validation, ...step2Validation }
    const validationError = validate.single(value, validator[name])
    if (validationError && validationError.length) {
      this.setState({
        errors: { ...this.state.errors, [name]: [validationError[0]] }, // When validating here, we only show the first error message
      })
    } else {
      this.setState({ errors: { ...this.state.errors, [name]: [] } })
    }
  }

  /**
   * Advances form to next step
   */
  nextStep = async () => {
    EventEmitter.dispatch('toggleLoading', { show: true })
    const { step } = this.state
    const {
      email,
      name,
      lastName,
      phone,
      address,
      city,
      nationalID,
    } = this.state
    const validationErrors = validate(
      {
        email,
        name,
        lastName,
        phone,
        address,
        city,
        nationalID,
      },
      step1Validation,
      { fullMessages: false }
    )
    if (validationErrors)
      return this.setState({
        errors: { ...this.state.errors, ...validationErrors },
      })

    const response = await functions.post(
      'checkUser',
      { dob: this.props.quoteData.dob, name, lastName },
      { json: true }
    )
    if (!response.data.success) {
      this.props.onExistingUser()
      EventEmitter.dispatch('toggleLoading', { show: false })
      return null
    }

    this.setState({ step: step + 1 }, () => {
      setTimeout(() => {
        this.setState({ step1Animation: '', step2Animation: 'text-slide-in' })
      }, 1)
    })

    EventEmitter.dispatch('toggleLoading', { show: false })
  }

  /**
   * Handles date change
   * @param {Date} date
   */
  handleDateChange = date => {
    this.setState({ dob: date })
  }

  /**
   * Handles terms and conditions
   * checkbox
   */
  handleTerms = position => {
    switch (position) {
      case 0:
        this.setState({ terms: !this.state.terms })
        break
      case 1:
        this.setState({ termsOne: !this.state.termsOne })
        break
      case 2:
        this.setState({ termsTwo: !this.state.termsTwo })
        break
      case 3:
        this.setState({ termsThree: !this.state.termsThree })
        break
      default:
        break
    }
  }

  /**
   * Submit forms
   * @param {Object} event
   */
  handleSubmit = async event => {
    event.preventDefault()
    const {
      email,
      password,
      confirmPassword,
      name,
      lastName,
      phone,
      address,
      city,
      terms,
      termsOne,
      termsTwo,
      termsThree,
      nationalID,
    } = this.state

    // If the user has not accepted terms
    if (!terms || !termsOne || !termsTwo || !termsThree) {
      // Error modal
      return false
    }

    /*
     * Validating confirm password
     * here because equality prop
     * in validate.js always seem to fail
     */
    if (password !== confirmPassword) {
      return this.setState({
        errors: {
          ...this.state.errors,
          confirmPassword: ['Las contraseñas deben ser iguales'],
        },
      })
    }
    // Validating one more time
    const validationErrors = validate(
      {
        email,
        password,
        name,
        lastName,
        phone,
        address,
        city,
        nationalID,
      },
      { ...step1Validation, ...step2Validation }
    )

    if (validationErrors)
      return this.setState({
        errors: { ...this.state.errors, ...validationErrors },
      })

    try {
      const user = {
        name,
        lastName,
        phone,
        email,
        address,
        city,
        nationalID,
        payed: false,
      }
      const quote = this.props.quoteData
      const selected = this.props.selected
      const refCode = this.props.refCode
      await handleRegister({ email, password, user, quote, selected, refCode })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { step } = this.state
    const step1 = (
      <Step1
        quoteData={this.props.quoteData || {}}
        state={this.state}
        handleInputChange={this.handleInputChange}
        validateInput={this.validateInput}
        nextStep={this.nextStep}
        handlePhoneInputChange={this.handlePhoneInputChange}
      />
    )
    const step3 = (
      <Step3
        state={this.state}
        handleInputChange={this.handleInputChange}
        validateInput={this.validateInput}
        handleTerms={this.handleTerms}
      />
    )
    return (
      <div className="container mx-auto">
        <div className="grid grid-cols-12">
          <div className="col-start-2 col-span-10 lg:col-start-5 lg:col-span-4 text-center">
            <form onSubmit={this.handleSubmit}>
              {step === 1 ? step1 : step3}
            </form>
          </div>
        </div>
      </div>
    )
  }
}
