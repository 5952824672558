import React from 'react'
import Input from "../input"
import { EventEmitter } from '../../../services/events'
import Tos from "../../tos/tos-registro"

export default function Step2({ state, handleInputChange, validateInput, handleTerms }) {

  const openModal = () => {
    EventEmitter.dispatch("toggleModal", {
      show: true, success: false, noIcon: true, title: "Terminos y Condiciones",
      content: <Tos/>
    })
  }

  return (
    <div className={state.step2Animation}>
      <div className="form-input">
        <Input
          type="email"
          name="email"
          errors={state.errors["email"]}
          placeholder="Correo electrónico *"
          value={state.email}
          onBlur={validateInput}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-input">
        <Input
          type="password"
          name="password"
          errors={state.errors["password"]}
          placeholder="Contraseña *"
          value={state.password}
          onBlur={validateInput}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-input">
        <Input
          type="password"
          name="confirmPassword"
          errors={state.errors["confirmPassword"]}
          placeholder="Confirma tu contraseña *"
          value={state.confirmPassword}
          onBlur={validateInput}
          onChange={handleInputChange}
        />
      </div>
      <div className="text-xs leading-tight text-left p-3 shadow rounded-sm">
        <div className="flex flex-row">
          <div className="flex-initial">
            <Input
              type="checkbox"
              name="termsOne"
              errors={state.errors["termsOne"]}
              classes="mt-1 ml-1 h-4 w-4"
              value={state.termsOne}
              onBlur={validateInput}
              onChange={() => handleTerms(1)}
            />
          </div>
          <div className="flex-1">
            <p className="mb-2 ml-2">
              Declaro que todos los datos proporcionados son
              ciertos, completos, libres de falsificación y omisión.
              </p>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex-initial">
            <Input
              type="checkbox"
              name="termsTwo"
              errors={state.errors["termsTwo"]}
              classes="mt-1 ml-1 h-4 w-4"
              value={state.termsTwo}
              onBlur={validateInput}
              onChange={() => handleTerms(2)}
            />
          </div>
          <div className="flex-1">
            <p className="mb-2 ml-2">
            Declaro que no conozco, ni he sido diagnosticado con alguna 
            enfermedad o condición grave de salud que ponga en riesgo 
            mi integridad física o mi vida.
              </p>
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex-initial">
            <Input
              type="checkbox"
              name="termsThree"
              errors={state.errors["termsThree"]}
              classes="mt-1 ml-1 h-4 w-4"
              value={state.termsThree}
              onBlur={validateInput}
              onChange={() => handleTerms(3)}
            />
          </div>
          <div className="flex-1">
            <p className="mb-0 mt-1 ml-2">
              Declaro que no consumo drogas recreativas ni sufro
              de alcoholismo.
              </p>
          </div>
        </div>
      </div>

      <div className="form-input mt-2">
        <label
          className="flex justify-center items-center whitespace-no-wrap text-center"
          htmlFor="terms">
          <Input
            type="checkbox"
            name="terms"
            errors={state.errors["terms"]}
            classes="mr-2 align-middle h-4 w-4"
            value={state.terms}
            onBlur={validateInput}
            onChange={() => handleTerms(0)}
          />
          <span className="align-middle">
            <div role='button' tabIndex={0} onKeyDown={() => null} onClick={openModal} className="text-blue">
              Acepto los términos y condiciones
              </div>
          </span>
        </label>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <button
            className="default-button w-full py-3 px-8 mb-56 mt-3"
            type="submit">
            Registrarme
            </button>
        </div>
      </div>
    </div>
  )
}