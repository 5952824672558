import React from 'react'
import { navigate } from 'gatsby'

// Components
import FailureIcon from '../../images/failure-icon'

const PreexistingUser = () => (
  <div className="w-full mx-auto max-w-xs mt-24 md:mt-40 px-4 flex flex-col items-center text-center">
    <div className="w-20 mb-8">
      <FailureIcon />
    </div>
    <p className="font-semibold text-lg">Usuario existente</p>
    <p>Lo sentimos, al parecer ya tienes contratada una póliza com Alex.</p>
    <p>Recuerda que solo puedes contratar una póliza por persona.</p>
    <button
      onClick={() => navigate('/quote')}
      className="default-button mt-4 mb-8 py-3 px-16 mx-auto rounded"
    >
      Entendido
    </button>
  </div>
)

export default PreexistingUser
