export default {
  name: {
    presence: { allowEmpty: false, message: "Campo requerido." }
  },
  lastName: {
    presence: { allowEmpty: false, message: "Campo requerido." }
  },
  nationalID: {
    presence: { allowEmpty: false, message: "Campo requerido." }
  },
  phone: {
    presence: { allowEmpty: false, message: "Campo requerido." },
  },
  address: {
    presence: { allowEmpty: false, message: "Campo requerido." }
  },
  city: {
    presence: { allowEmpty: false, message: "Campo requerido." }
  }
}